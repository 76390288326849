import { render, staticRenderFns } from "./TextOnly.vue?vue&type=template&id=23eeb3bf&scoped=true&"
import script from "./TextOnly.vue?vue&type=script&lang=ts&"
export * from "./TextOnly.vue?vue&type=script&lang=ts&"
import style0 from "./TextOnly.vue?vue&type=style&index=0&id=23eeb3bf&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23eeb3bf",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AtomsKELabel: require('/var/www/packages/theme/components/atoms/KELabel.vue').default,MoleculesCmsUrlWrapper: require('/var/www/packages/theme/components/molecules/CmsUrlWrapper.vue').default})
