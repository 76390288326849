

















import { defineComponent, PropType } from '@nuxtjs/composition-api';

type Brand = {
  id: number;
  title: string;
  url: string;
  media_path: string;
  overlay_text?: string;
};

export default defineComponent({
  name: 'TextOnly',
  props: {
    data: {
      type: Object as PropType<{
        urls?: Brand[];
        custom_fields: Record<string, any>;
      }>,
      required: true,
      default: () => ({ media: [] })
    }
  }
});
