import { render, staticRenderFns } from "./LogosOnly.vue?vue&type=template&id=3425f40c&scoped=true&"
import script from "./LogosOnly.vue?vue&type=script&lang=ts&"
export * from "./LogosOnly.vue?vue&type=script&lang=ts&"
import style0 from "./LogosOnly.vue?vue&type=style&index=0&id=3425f40c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3425f40c",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AtomsImageOrFallback: require('/var/www/packages/theme/components/atoms/ImageOrFallback.vue').default,MoleculesCmsUrlWrapper: require('/var/www/packages/theme/components/molecules/CmsUrlWrapper.vue').default})
