import { render, staticRenderFns } from "./BrandLogoHighlights.vue?vue&type=template&id=2295c784&scoped=true&"
import script from "./BrandLogoHighlights.vue?vue&type=script&lang=ts&"
export * from "./BrandLogoHighlights.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2295c784",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CmsComponentsBrandLogoHighlightsTextOnly: require('/var/www/packages/theme/components/cms-components/brand-logo-highlights/TextOnly.vue').default,CmsComponentsBrandLogoHighlightsLogosOnly: require('/var/www/packages/theme/components/cms-components/brand-logo-highlights/LogosOnly.vue').default})
