






import { defineComponent, computed, PropType } from '@nuxtjs/composition-api';

type Brand = {
  id: number;
  title: string;
  url: string;
  media_path: string;
  overlay_text?: string;
};

export default defineComponent({
  name: 'BrandLogoHighlights',
  props: {
    data: {
      type: Object as PropType<{
        urls?: Brand[];
        custom_fields: Record<string, any>;
      }>,
      required: true,
      default: () => ({ media: [] })
    }
  },
  setup(props) {
    const isTextOnly = computed(() => {
      const customFields = props.data?.custom_fields || [];
      const displayType = customFields.find(
        (field) => field.key === 'display_type'
      );
      if (!displayType) return false;
      return displayType.value_select === 'minimized_with_actionlabel';
    });

    return {
      isTextOnly
    };
  }
});
